import { savePlayer } from "@/assets/scripts/load";

// recepie actions
export default {
    addItem({ commit, getters }, recipe) {
        let r = getters['getById'](recipe.wType.id);
        if (r == undefined) {
            let r = { id: recipe.wType.id, unlocked: false, percent: 1, maxPercent: 3, name: recipe.wType.name }
            commit('addItem', r);
        } else {
            r.percent += 1;
            if (r.percent >= r.maxPercent) {
                r.unlocked = true;
                delete r.percent;
                delete r.maxPercent;

                commit('showSnackbar',
                    {
                        color: 'success',
                        text: 'You have unlocked a new recipe ' + recipe.name.split(" ")[1] + '!',
                    }, { root: true });

                commit("log/add", { text: `You have unlocked a new recipe: {}!`, item: recipe }, { root: true })


                savePlayer();
            }
        }
    },
}