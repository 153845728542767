// forge actions
export default {
    createItem({ commit, getters }, item) {
        if (getters["hasMaterial"](item)) {
            commit('backpack/removeAmount', { item: item.material, amount: item.wType.size }, { root: true })
            let clone = JSON.parse(JSON.stringify(item));
            clone.time = null;
            clone.uid = Date.now() + clone.id;

            commit("addForge", clone)
        }
    },
    update({ state, commit, dispatch }) {
        if (state.forging.length == 0 || state.forging[0] == undefined) return;
        let item = state.forging[0]

        if (item.time == undefined) {
            item.time = 0;
            item.maxTime = 10;
        } else {
            item.time += 1;
        }

        if (item.time > item.maxTime) {
            item.time = null;
            dispatch('backpack/addItems', { item: item }, { root: true });

            commit('showSnackbar',
                {
                    text: 'You forged ' + item.name + '!',
                    color: 'success'
                },
                { root: true });

            commit("log/add", { text: 'You successfully forged {}!', item: item }, { root: true })

            commit('removeForge', item);
        }
    },
    removeToBackpack({ commit, dispatch }, item) {
        commit('removeForge', item);
        dispatch('backpack/addItems', { item: item.material, amount: item.wType.size }, { root: true })
    },

}