import { savePlayer } from "@/assets/scripts/load"

export default {
    addItems({ commit, state }, { item, amount = 1 }) {
        let oldItem = state.items.find((x) => x.id === item.id);

        if (oldItem) {
            oldItem.amount += parseInt(amount);
        } else {
            let clone = JSON.parse(JSON.stringify(item));
            clone.amount = parseInt(amount);
            clone.uid = Date.now() + clone.id;
            commit("addItem", clone)
        }
    },
    removeItems({ commit }, { id, amount }) {
        for (let i = 0; i < amount; i++) {
            commit("deleteById", id)
        }
        savePlayer();
    },
    selectItem({ commit }, item) {
        commit("selectItem", item)
    },
    unselectItem({ commit }) {
        commit("unselectItem")
    },
    equipItem({ rootState, commit, dispatch }, item) {
        let save = rootState.fight.player.weapon;
        rootState.fight.player.weapon = item;
        if (save.id !== "fist")
            dispatch("addItems", { item: save, amount: 1 })
        commit("removeAmount", { item: item, amount: 1 })

        commit("log/add", { text: `You equipped {}`, item: item }, { root: true })

        savePlayer();
    }
}