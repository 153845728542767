export default {
    setItems(state, items) {
        state.items = items;
    },
    addForge(state, item) {
        state.forging.push(item);
    },
    removeForge(state, item) {
        let index = state.forging.findIndex(i => i.uid === item.uid);
        if (index > -1) {
            state.forging.splice(index, 1);
        }
    },

}