export default {
    setStage(state, stage) {
        state.stage = stage;
    },
    setEnemy(state, enemy) {
        state.enemy = enemy;
    },
    setPlayer(state, player) {
        state.player = player;
    },
    setDeadTimer(state, time) {
        state.deadTimer = time;
    },
    removeWeapon(state) {
        state.player.weapon = null;
    }
}