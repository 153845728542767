import store from '@/store';
import { createRouter, createWebHashHistory } from 'vue-router'
import { wikiRoutes } from './wiki';

let routes = [
  { path: "/", redirect: "/map" },
  {
    path: '/fight',
    name: 'Fight',
    meta: {
      title: 'Fight', icon: 'mdi-sword-cross', id: 'fight'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/FightView.vue')
  },
  {
    path: '/status',
    name: 'Status',
    meta: {
      title: 'Status', icon: 'mdi-account', id: 'status'
    },
    component: () => import(/* webpackChunkName: "status" */ '../views/StatusView.vue')
  },
  {
    path: '/backpack',
    name: 'Backpack',
    meta: {
      title: 'Backpack', icon: 'mdi-bag-personal', id: 'backpack'
    },
    component: () => import(/* webpackChunkName: "backpack" */ '../views/BackpackView.vue')
  },
  {
    path: '/map',
    name: 'Map',
    meta: {
      title: 'Map', icon: 'mdi-map', id: 'map'
    },
    component: () => import(/* webpackChunkName: "map" */ '../views/MapView.vue')
  },
  {
    path: '/smelter',
    name: 'Smelter',
    meta: {
      title: 'Smelter', icon: 'mdi-fire', id: 'smelter'
    },
    component: () => import(/* webpackChunkName: "smelter" */ '../views/SmelterView.vue')
  },
  {
    path: '/forge',
    name: 'Forge',
    meta: {
      title: 'Forge', icon: 'mdi-anvil', id: 'forge'
    },
    component: () => import(/* webpackChunkName: "forge" */ '../views/ForgeView.vue')
  },
  {
    path: '/library',
    name: 'Library',
    meta: {
      title: 'Library', icon: 'mdi-library', id: 'library'
    },
    component: () => import(/* webpackChunkName: "library" */ '../views/LibraryView.vue')
  },
  {
    path: '/thinker',
    name: 'Thinker',
    meta: {
      title: 'Thinker', icon: 'mdi-hammer-wrench', id: 'thinker'
    },
    component: () => import(/* webpackChunkName: "thinker" */ '../views/ThinkerView.vue')
  },
  {
    path: '/enchanter',
    name: 'Enchanter',
    meta: {
      title: 'Enchanter', icon: 'mdi-magic-staff', id: 'enchanter'
    },
    component: () => import(/* webpackChunkName: "enchanter" */ '../views/EnchanterView.vue')
  },
  {
    path: '/packs',
    name: 'Packs',
    meta: {
      title: 'Packs', icon: 'mdi-cards', id: 'packs'
    },
    component: () => import(/* webpackChunkName: "packs" */ '../views/PacksView.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      title: 'Settings', icon: 'mdi-cog', id: 'settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
  },

  { path: '/:pathMatch(.*)*', redirect: "/map" },
]

//add wiki routes array
routes = routes.concat(wikiRoutes);

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Fight' && !store.state.fight.stage) {
    next({ name: 'Map' });

  }
  else if (to.meta.isWiki) {
    next()
  }
  else if (to.meta.id && !store.state.unlocked.includes(to.meta.id)) {
    console.log('locked');
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router
