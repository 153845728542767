import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import state from './state';


const module = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default module;