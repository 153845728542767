<template>
  <v-app v-if="loaded" id="inspire">
    <v-app-bar>
      <v-app-bar-nav-icon v-if="dloaded" @click="openDrawer" />


      <v-app-bar-title>{{ getCurrentTitle }}</v-app-bar-title>

      <v-spacer />

      <v-btn icon @click="openWiki">
        <v-icon v-if="!isWiki">{{ getCurrentIcon }}</v-icon>
        <v-icon v-else>mdi-tools</v-icon>
      </v-btn>

      <v-btn icon @click="savePlayer">
        <v-icon>mdi-content-save-outline</v-icon>
      </v-btn>

      <v-btn v-if="sloaded" icon @click="openSidebar">
        <v-icon>mdi-book-open-page-variant</v-icon>
      </v-btn>

    </v-app-bar>
    <NavigationBar ref="navBar" @dloaded="dloaded = true" />

    <SideBar ref="sideBar" @sloaded="sloaded = true" />

    <v-main class="bg-grey-lighten-2">
      <router-view />
      <MiniFight v-if="$route.path != '/fight'" />
    </v-main>

    <SnackBar />
  </v-app>
  <LoadItem v-else />
</template>

<script>
import { defineAsyncComponent } from 'vue'
import LoadItem from '@/components/Util/Loading.vue';
export default {
  data() {
    return {
      loaded: false,
      dloaded: false,
      sloaded: false
    };
  },
  created() {
    this.$store.dispatch('preload').then(() => {
      this.$store.dispatch("fight/initPlayer")
      this.loaded = true;
    });
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      window.addEventListener('beforeunload', this.savePlayer);
    }
  },
  components: {
    NavigationBar: defineAsyncComponent(() => import('@/components/NavigationBar.vue')),
    MiniFight: defineAsyncComponent(() => import('@/components/Fight/MiniFight.vue')),
    SnackBar: defineAsyncComponent(() => import('@/components/Util/SnackBar.vue')),
    SideBar: defineAsyncComponent(() => import('@/components/Log/Sidebar.vue')),
    LoadItem
  },

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.savePlayer);
  },
  computed: {
    getCurrentIcon() {
      return this.$store.getters.getCurrentIcon;
    },
    getCurrentTitle() {
      return this.$store.getters.getCurrentTitle;
    },
    getCurrentWiki() {
      return this.$store.getters.getCurrentWiki;
    },
    isWiki() {
      return this.$store.getters.isWiki;
    }
  },
  methods: {
    openDrawer() {
      this.$refs.navBar.drawer = !this.$refs.navBar.drawer;
    },
    openSidebar() {
      this.$refs.sideBar.drawer = !this.$refs.sideBar.drawer;
    },
    savePlayer() {
      this.$store.dispatch('savePlayer');
    },
    openWiki() {
      let path = this.getCurrentWiki
      this.$router.push({ path: `${path}` })
    }
  }
}
</script>

<style lang="scss" scoped></style>