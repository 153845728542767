export function getDPS(weapon) {
    if (weapon.wType.type === "gun") {
        return CalculateGunDps(weapon.damage, weapon.speed, weapon.threshold, weapon.wType.stats.reload, weapon.wType.stats.maxAmmo);
    } else {
        return CalculateDps(weapon.damage, weapon.speed, weapon.threshold);
    }
}


export function CalculateDps(damage, speed, threshold) {
    let dps = 0;
    let cthreshold = 0;
    let time = 1000;

    for (let i = 0; i < time; i++) {
        cthreshold += speed;

        if (cthreshold >= threshold) {
            dps += damage;
            cthreshold = 0;
        }
    }
    return (dps / time).toFixed(2);
}


function CalculateGunDps(damage, speed, threshold, reloadTime, maxAmmo) {
    let dps = 0;
    let cthreshold = 0;
    let ammo = maxAmmo;
    let time = 1000;

    for (let i = 0; i < time; i++) {
        if (ammo > 0) {
            cthreshold += speed;
            if (cthreshold >= threshold) {
                dps += damage;
                cthreshold = 0;
                ammo--;
            }
        } else {
            cthreshold += speed;
            if (cthreshold >= reloadTime) {
                ammo = maxAmmo;
                cthreshold = 0;
            }
        }
    }

    return (dps / time).toFixed(2);
}
