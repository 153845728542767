import store from "@/store/index";

export function createPlayer() {
    let weapon = store.getters['forge/getData'].find(weapon => weapon.id === "fist");
    let clone = JSON.parse(JSON.stringify(weapon));
    clone.uid = Date.now() + clone.id;
    return {
        id: 'player',
        name: 'Player',
        health: 10,
        maxHealth: 20,
        weapon: clone
    }
}