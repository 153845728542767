<template>
    <div class="load-container"
        :style="{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('${backgroundImage}')` }">
        <v-card class="minicontainer" dark>
            <div class="image-container">
                <img src="@/assets/img/background/loading-image.webp" alt="Loading" class="loading-image" />
            </div>
            <div class="load-text">
                {{ loadText }}
            </div>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "LoadItem",
    computed: {
        ...mapState(['loadText']),
        backgroundImage() {
            return require(`@/assets/img/background/enchantedForest.webp`);
        }
    },
}
</script>

<style lang="scss" scoped>
.minicontainer {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.load-text {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-top: 170px;
}

.load-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    overflow: auto;
}

.image-container {
    position: absolute;
    animation: spin 2s linear infinite;
}

.loading-image {
    width: 150px;
    height: 150px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.progress-circular {
    position: relative;
    z-index: 1;
}
</style>
