import store from "@/store/index";

export function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function clone(item) {
    return JSON.parse(JSON.stringify(item));
}

export function checkBadge(badge) {
    if (badge.name == "Library") {
        return store.getters["research/checkBadge"];
    }
    if (badge.name == "Packs") {
        return store.getters["packs/checkBadge"];
    }
}

export function splitCamelCaseString(camelCaseString) {
    return camelCaseString
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(' ')
        .map(word => word.toLowerCase());
}