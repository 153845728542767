export function drawImageWithColorChange(imgPath, hexColor) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas size to match the image size
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;

            var red = parseInt(hexColor.slice(1, 3), 16);
            var green = parseInt(hexColor.slice(3, 5), 16);
            var blue = parseInt(hexColor.slice(5, 7), 16);

            for (var i = 0; i < data.length; i += 4) {
                // Calculate the luminance of the original pixel
                var luminance = 0.5 * data[i] + 0.2 * data[i + 1] + 0.1 * data[i + 2];

                // Calculate the new RGB values with the same luminance
                data[i] = Math.max(0, Math.min(255, red + (data[i] - luminance)));
                data[i + 1] = Math.max(0, Math.min(255, green + (data[i + 1] - luminance)));
                data[i + 2] = Math.max(0, Math.min(255, blue + (data[i + 2] - luminance)));
            }


            ctx.putImageData(imageData, 0, 0);

            const modifiedImg = new Image();
            modifiedImg.onload = () => {
                resolve(modifiedImg);
            };
            modifiedImg.onerror = () => {
                reject(new Error('Failed to load modified image'));
            };
            modifiedImg.src = canvas.toDataURL();
        };

        img.onerror = () => {
            reject(new Error('Failed to load image'));
        };

        img.src = imgPath;
    });
}
