import perks from '@/assets/json/perks';
import { roundToTwo } from "@/assets/scripts/utils.js";
import { getDPS } from "@/assets/scripts/weapons/dps";
import { generatePerkWeaponName, generatePerkWeaponId } from "@/assets/scripts/weapons/name.js";

export function addPerk(item, perk) {
    if (item == null) {
        console.error('Item is required');
        return null;
    }

    if (item.perk) {
        revertPerk(item, item.perk)
        item.perk = null
    }

    item.name = generatePerkWeaponName(item, perk);
    item.perk = perk;
    calculatePerk(item, perk);
    item.id = generatePerkWeaponId(item, perk);
}

export function addRandomPerk(item) {
    addPerk(item, perks[Math.floor(Math.random() * perks.length)]);
}

function revertPerk(item, perk) {
    for (let stat in perk.stats) {
        if (item[stat]) {
            item[stat] = roundToTwo(item[stat] / perk.stats[stat]);
        }
    }
    item.dps = getDPS(item);
}

function calculatePerk(item, perk) {
    for (let stat in perk.stats) {
        if (item[stat]) {
            item[stat] = roundToTwo(item[stat] * perk.stats[stat]);
        }
    }

    item.dps = getDPS(item);
}

export function addPerkById(item, perkID) {
    const perk = perks.find(perk => perk.id === perkID);
    addPerk(item, perk);
}