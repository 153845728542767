export default {
    setItems(state, items) {
        state.items = items;
    },
    addItem(state, item) {
        state.items.push(item);
    },
    removeAmount(state, { item, amount }) {
        const found = state.items.find(i => i.id === item.id);
        if (found) {
            found.amount -= amount;
            if (found.amount <= 0)
                state.items.splice(state.items.indexOf(item), 1);
        }
    },
    lockItem(state, item) {
        const found = state.items.find(i => i.id === item.id);

        if (found) {
            if (found.locked == undefined) found.locked = false;
            found.locked = !found.locked;
        }
    },
    deleteItem(state, item) {
        const index = state.items.findIndex(i => i.id === item.id);
        if (index > -1)
            state.items.splice(index, 1);
    },
    deleteById(state, id) {
        const index = state.items.findIndex(i => i.id === id);
        if (index > -1)
            state.items.splice(index, 1);
    },
    selectItem(state, item) {
        state.selectedItem = item;
    },
    unselectItem(state) {
        state.selectedItem = null;
    },
}