export default {
    setItems(state, items) {
        state.items = items;
    },
    addItem(state, item) {
        state.items.push(item)
    },
    removeItem(state, item) {
        let index = state.items.findIndex(i => i.uid === item.uid);
        if (index > -1) {
            state.items.splice(index, 1);
        }
    }
}