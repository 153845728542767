export let wikiRoutes = [
    {

        path: '/wiki/smelter',
        name: 'SmelterWiki',
        meta: {
            title: 'Smelter', icon: 'mdi-fire', base: "smelter", id: 'smelterWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/SmelterWiki.vue')
    },
    {
        name: "LibraryWiki",
        path: '/wiki/library',
        meta: {
            title: 'Library', icon: 'mdi-book', base: "library", id: 'libraryWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/LibraryWiki.vue')
    },
    {
        name: "ForgeWiki",
        path: '/wiki/forge',
        meta: {
            title: 'Forge', icon: 'mdi-anvil', base: "forge", id: 'forgeWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/ForgeWiki.vue')
    },
    {
        name: "BackpackWiki",
        path: '/wiki/backpack',
        meta: {
            title: 'Backpack', icon: 'mdi-bag-personal', base: "backpack", id: 'backpackWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/BackpackWiki.vue')
    },
    {
        name: "StatusWiki",
        path: '/wiki/status',
        meta: {
            title: 'Status', icon: 'mdi-account', base: "status", id: 'statusWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/StatusWiki.vue')
    },
    {
        name: "MapWiki",
        path: '/wiki/map',
        meta: {
            title: 'Map', icon: 'mdi-map', base: "map", id: 'mapWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/MapWiki.vue')
    },
    { path: "/wiki/fight", redirect: "/wiki/map" },
    {
        name: "SettingsWiki",
        path: '/wiki/settings',
        meta: {
            title: 'Settings', icon: 'mdi-cog', base: "settings", id: 'settingsWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/SettingsWiki.vue')
    },
    {
        name: "PacksWiki",
        path: '/wiki/packs',
        meta: {
            title: 'Packs', icon: 'mdi-cards', base: "packs", id: 'packsWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/PacksWiki.vue')
    },
    {
        name: "ThinkerWiki",
        path: '/wiki/thinker',
        meta: {
            title: 'Thinker', icon: 'mdi-hammer-wrench', base: "thinker", id: 'thinkerWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/ThinkerWiki.vue')
    },
    {
        name: "EnchanterWiki",
        path: '/wiki/enchanter',
        meta: {
            title: 'Enchanter', icon: 'mdi-magic-staff', base: "enchanter", id: 'enchanterWiki', isWiki: true,
        },
        component: () => import(/* webpackChunkName: "wiki" */ '../views/wiki/EnchanterWiki.vue')
    }

]