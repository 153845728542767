export default {
    getItems: state => {
        return state.items;
    },
    current: (state, getters, rootState, rootGetters) => {
        let all = rootGetters['backpack/getItems'];
        return all.filter(i => i.type === 'weapon' && !getters.canResearchBy(i));
    },
    isResearched: (state, getters, rootState, rootGetters) => id => {
        const item = rootGetters['recipes/getItems'].find(i => i.id === id);
        return item?.unlocked || false;
    },
    checkBadge(state, getters) {
        return getters.current.length > 0;
    },
    canResearchBy: (state, getters, rootState, rootGetters) => item => {
        const all = rootGetters['recipes/getItems'];
        const recipe = all.find(i => i.id === item.wType.id);

        if (recipe && recipe.unlocked) {
            return true;
        }

        const researchingItems = state.items.filter(i => i.id == item.id).length;

        if (recipe) {
            return researchingItems + recipe.percent >= 3;
        }

        return researchingItems >= 3;
    }


}