export default {
    getItems: (state) => {
        return state.items;
    },

    getItemById: (state) => id => {
        return state.items.find(item => item.id === id);
    },

    getSortedItems(state) {
        let itemsToProcess = [...state.items];
        return itemsToProcess;
    },

    getWeapons(state, getters) {
        return getters.getSortedItems.filter(item => item.type == "weapon");
    },

    getGuns(state, getters) {
        return getters.getWeapons.filter(item => item.wType.type === "gun");
    },

    isGrouped(state) {
        return state.filter.grouped;
    },

    isSorted(state) {
        return state.filter.sorted;
    },

    getSelectedItem(state) {
        return state.selectedItem;
    }
}