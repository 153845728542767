import { addPerk } from "@/assets/scripts/weapons/perk";

export default {
    upgradeItem({ getters, dispatch, commit }, perk) {
        let dismantle = getters.dismantleItem;
        let upgrade = getters.upgradedItem;

        if (!upgrade || !dismantle) return;

        let hasItems = getters["hasItem"](dismantle) && getters["hasItem"](upgrade);

        if (upgrade.id === dismantle.id) {
            hasItems = upgrade.amount > 1;
        }

        if (hasItems) {
            commit('backpack/removeAmount', { item: dismantle, amount: 1 }, { root: true });
            let clone = JSON.parse(JSON.stringify(upgrade));
            addPerk(clone, perk);

            commit('backpack/removeAmount', { item: upgrade, amount: 1 }, { root: true });
            dispatch('backpack/addItems', { item: clone }, { root: true });

            commit('showSnackbar',
                {
                    text: `You've upgraded ${clone.name}!`,
                    color: 'success'
                }, { root: true });

            if (!getters["hasItem"](dismantle)) {
                commit("setDismantleItem", null);
            }

            if (!getters["hasItem"](upgrade)) {
                commit("setUpgradedItem", null);
            }

        }


    },

}