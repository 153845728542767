export default {
    getItems: (state) => {
        return state.items;
    },
    upgradedItem: (state) => {
        return state.upgradedItem;
    },
    dismantleItem: (state) => {
        return state.dismantleItem;
    },
    hasItem: (state, getters, rootState) => item => {
        let ob = rootState.backpack.items.filter(i => i.id == item.id)
        return ob && ob.length > 0;
    },
}