import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'
import backpack from './backpack'
import fight from './fight'
import research from './research'
import smelter from './smelter'
import recipes from './recipes'
import forge from './forge'
import packs from './packs'
import thinker from './thinker'
import enchanter from './enchanter'
import log from './log'

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    log,
    fight,
    backpack,
    research,
    smelter,
    recipes,
    forge,
    packs,
    thinker,
    enchanter
  }
})
