export default {
    gameSpeed: 200,
    timer: {},
    unlocked: ["status", "map", "packs", "backpack", "fight", "settings"],
    hideImages: false,
    images: [],
    snackbar: {
        visible: false,
        color: '',
        text: '',
    },
    loadText: 'starting game...',
}