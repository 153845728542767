export default {
    getItems: (state) => {
        return state.items;
    },
    getAllPacks: (state, getters, rootState) => {
        return rootState.data.packs.filter((pack) => {
            return !state.items.includes(pack.id);
        });
    },
    hasEnoughItems: (state, getters, rootState, rootGetters) => (pack) => {
        let item = rootGetters['backpack/getItemById'](pack.cost.id);
        let amount = item ? item.amount : 0;
        return amount >= pack.cost.amount;
    },
    checkBadge: (state, getters) => {
        for (let item of getters.getAllPacks) {
            if (getters["hasEnoughItems"](item)) {
                return true;
            }
        }
        return false
    }
}