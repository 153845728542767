import router from "@/router";

export default {
    getCurrentIcon: () => {
        return router.currentRoute.value.meta.icon
    },

    hideNpc: state => {
        return state.fight.stage != null && !state.hideImages;
    },

    getCurrentTitle: () => {
        return router.currentRoute.value.meta.title
    },

    getCurrentWiki: (state, getters) => {
        if (!getters.isWiki) {
            return "/wiki/" + router.currentRoute.value.meta.id;
        }
        return "/" + router.currentRoute.value.meta.base;
    },

    isWiki: () => {
        return !!router.currentRoute.value.meta.isWiki;
    },

    getImageById: (state) => (id) => {
        let item = state.images.find(image => image.id === id);
        return item ? item.image : null;
    },

    getUnlockedMaps: (state) => {
        const packItems = state.packs.items;

        return state.data.map.filter((map) => {
            return packItems.includes(map.pack);
        });
    }
}