import store from "@/store/index";
import { roundToTwo, clone } from "@/assets/scripts/utils.js";
import { getDPS, CalculateDps } from "@/assets/scripts/weapons/dps.js";
import { generateWeaponName, generateWeaponId } from "@/assets/scripts/weapons/name.js";

export function getWeaponById(id) {
    return clone(store.getters['forge/getItemById'](id));
}

export function calculateWeapon(weapon) {
    const { ores, woods, wTypes } = store.state.data;
    const ore = ores.find(o => o.id === weapon.ore);
    const wood = woods.find(w => w.id === weapon.wood);
    const wType = wTypes.find(t => t.id === weapon.type);

    let material = wType.material == 'ore' ? ore : wood;

    let damage = roundToTwo(material.stats.damage * wType.stats.damage);
    let speed = roundToTwo(material.stats.speed * wType.stats.speed);
    let threshold = roundToTwo(material.stats.threshold * wType.stats.threshold);

    let name = generateWeaponName(material, wType);
    let id = generateWeaponId(material, wType);

    let result = { id, damage, speed, threshold, name, material, type: "weapon", wType }

    result.dps = getDPS(result);

    return result;
}

export function calculateFist() {
    let name = "Fist"
    let id = "fist"

    let wType = { id: "fist", name: "Fist", type: "blunt", material: "flesh", hands: 2, stats: { damage: 1, speed: 2.5, threshold: 1 }, pack: "starter" }
    let material = {
        "id": "flesh",
        "name": "Flesh",
        "color": "#d0d0d0",
        "stats": {
            "damage": 1,
            "speed": 1,
            "threshold": 20
        },
        "pack": "starter"
    };

    let result = { id, damage: 1, speed: 2.5, threshold: 20, name, material, type: "weapon", wType }

    result.dps = CalculateDps(result.damage, result.speed, result.threshold);

    return result;
}

