import { clone } from "@/assets/scripts/utils"

export default {
    add(state, item) {
        let cloned = clone(item)
        state.items.push(cloned)

        if (state.items.length > 60) {
            state.items.shift();
        }
    }
}