import router from "@/router";
import { createPlayer } from "@/assets/scripts/player";
import { createEnemy } from "@/assets/scripts/enemy";
import { respawn, updateFight } from "@/assets/scripts/fight";
import { savePlayer } from "@/assets/scripts/load"
import { getWeaponById } from '@/assets/scripts/weapons/main.js';

export default {
    setStage({ state, dispatch, commit }, stage) {
        if (state.stage != stage) {
            dispatch("clearTimer");
        }

        commit('setStage', stage);
        router.push({ name: "Fight" });
    },
    exit({ dispatch, commit, state }) {
        dispatch("clearTimer");
        commit("setStage", null);

        const health = state.player.health / state.player.maxHealth;
        commit("setDeadTimer", 30 - (health * 30).toFixed(0));

        if (router.currentRoute._value.name == "Fight") {
            router.push({ name: "Map" });
        }
    },
    clearTimer({ state }) {
        clearInterval(state.timer);
        state.timer = null;
    },
    initFightTimer({ state, rootState }) {
        respawn(state.player);
        respawn(state.enemy);

        state.timer && clearInterval(state.timer);

        state.timer = setInterval(() => {
            updateFight(state.player, state.enemy)
            updateFight(state.enemy, state.player)
        }, rootState.gameSpeed);

    },
    initPlayer({ commit, state }) {
        return new Promise((resolve, reject) => {
            try {
                if (!state.player)
                    commit('setPlayer', createPlayer());
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    initFight({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                commit('setEnemy', createEnemy());
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    update({ state }) {
        if (state.deadTimer <= 0) {
            state.deadTimer = 0;
            return;
        }
        state.deadTimer -= 1;
    },
    died({ dispatch, commit }) {
        commit("log/add", { text: `You died` }, { root: true })

        commit("setDeadTimer", 30);
        dispatch('exit');
    },
    removeWeapon({ rootState, dispatch }) {
        let save = rootState.fight.player.weapon;
        const fist = getWeaponById("fist");
        rootState.fight.player.weapon = fist;
        dispatch("backpack/addItems", { item: save, amount: 1 }, { root: true });
        savePlayer();
    }
}