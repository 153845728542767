import store from "@/store/index";

export function createEnemy() {
    const state = store.state;

    let rand = Math.floor(Math.random() * state.fight.stage.enemies.length)
    let id = state.fight.stage.enemies[rand];
    return createEnemyById(id);
}

export function createEnemyById(id) {
    const state = store.state;

    let enemy = state.data.enemies.find(enemy => enemy.id === id)

    let weapon = store.getters['forge/getData'].find(weapon => weapon.id === enemy.weapon)

    let clone = JSON.parse(JSON.stringify(weapon));
    clone.uid = Date.now() + clone.id;

    return {
        id: enemy.id,
        name: enemy.name,
        health: enemy.maxHealth,
        maxHealth: enemy.maxHealth,
        weapon: clone,
    }
}