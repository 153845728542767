export default {
    getItems: (state, getters, rootState) => {
        let recipesUnlocked = state.items.filter(item => {
            let isRecipeUnlocked = rootState.recipes.items.some(r => r.id === item.wType.id && r.unlocked);

            let isOrePackUnlocked = rootState.packs.items.includes(item.material.pack);
            let isWTypePackUnlocked = rootState.packs.items.includes(item.wType.pack);

            return isRecipeUnlocked && isOrePackUnlocked && isWTypePackUnlocked;
        });

        return recipesUnlocked;
    },
    getItemById(state) {
        return id => {
            return state.items.find(i => i.id == id);
        }
    },
    getRecipes: (state, getters, rootState) => {
        return rootState.recipes.items.filter(r => !r.unlocked);
    },
    getForging: state => {
        return state.forging;
    },
    getData: state => {
        return state.items;
    },
    hasMaterial: (state, getters, rootState) => item => {
        let material = rootState.backpack.items.find(i => i.id == item.material.id);
        if (material == undefined) return 0;
        return material.amount >= item.wType.size;
    },
    isUnlocked: (state, getters, rootState) => item => {
        return rootState.recipes.items.find(r => r.type == item.type).unlocked
    }
}