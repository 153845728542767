import { savePlayer } from "@/assets/scripts/load";
import packs from "@/assets/json/packs"
export default {
    buy({ commit, rootGetters, dispatch }, pack) {
        if (rootGetters['packs/hasEnoughItems'](pack)) {
            dispatch('backpack/removeItems', { id: pack.cost.id, amount: pack.cost.amount }, { root: true });
            commit('addPack', pack.id);
            commit('showSnackbar',
                {
                    text: 'You bought ' + pack.name + '!',
                    color: 'success'
                }, { root: true });
            dispatch("unlockFeatures")
            savePlayer();
        } else {
            commit('showSnackbar',
                {
                    text: 'Not enough ' + pack.cost.id + ' to buy ' + pack.name + '!',
                    color: 'error'
                }, { root: true });
        }
    },
    unlockFeatures({ getters, commit }) {
        let items = getters.getItems;
        console.log(items)
        for (let index = 0; index < items.length; index++) {
            const element = packs.find(x => x.id == items[index]);
            for (let feature of element.features) {
                commit("unlock", feature, { root: true })
            }
        }
    }
}