import store from "@/store/index";
import { calculateWeapon, calculateFist } from "@/assets/scripts/weapons/main.js";
import { drawImageWithColorChange } from "@/assets/scripts/chroma.js";
import { createPlayer } from "@/assets/scripts/player";

export function preloadJson() {
    return new Promise((resolve) => {
        let arr = [],
            items = [
                "map",
                "enemies",
                "ores",
                "woods",
                "wTypes",
                "packs",
            ],
            obj = {};

        for (let item of items) arr.push(import('@/assets/json/' + item + '.json'));

        Promise.all(arr).then((e) => {
            for (let i in items) obj[items[i]] = e[i].default;
            resolve(obj);
        });
    });
}


export function createAllWeapons() {
    return new Promise((resolve, reject) => {
        try {
            let weapons = [];
            let ores = store.state.data.ores;
            let woods = store.state.data.woods;
            let wTypes = store.state.data.wTypes;

            ores.forEach(ore => {
                wTypes.forEach(wType => {
                    if (wType.material === "ore") {
                        let item = {};
                        item.ore = ore.id;
                        item.type = wType.id;
                        let weapon = calculateWeapon(item);
                        weapons.push(weapon);
                    }
                    else if (wType.material === "wood") {

                        woods.forEach(wood => {
                            let item = {};
                            item.wood = wood.id;
                            item.type = wType.id;
                            let weapon = calculateWeapon(item);
                            weapons.push(weapon);
                        });
                    }
                });
            });

            let fist = calculateFist();
            weapons.push(fist)

            resolve(weapons);
        } catch (error) {
            reject(error);
        }
    });
}

export function createAllOreImages() {
    return new Promise((resolve, reject) => {
        const promises = [];

        let ores = store.state.data.ores;
        let woods = store.state.data.woods;

        ores.forEach((ore) => {
            let images = store.state.images;

            const promise = new Promise((innerResolve, innerReject) => {
                drawImageWithColorChange(require(`@/assets/img/materials/bar.webp`), ore.color)
                    .then((e) => {
                        images.push({
                            id: ore.id,
                            image: e,
                        });
                        innerResolve();
                    })
                    .catch((error) => {
                        innerReject(error);
                    });
            });

            promises.push(promise);
        });

        woods.forEach((wood) => {
            let images = store.state.images;

            const promise = new Promise((innerResolve, innerReject) => {
                drawImageWithColorChange(require(`@/assets/img/materials/wood.webp`), wood.color)
                    .then((e) => {
                        images.push({
                            id: wood.id,
                            image: e,
                        });
                        innerResolve();
                    })
                    .catch((error) => {
                        innerReject(error);
                    });
            });

            promises.push(promise);
        });

        Promise.all(promises)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function createAllWeaponImages(weapons) {
    return new Promise((resolve, reject) => {
        const promises = [];
        let images = store.state.images;

        weapons.forEach((weapon) => {
            let material = weapon.material

            const promise = new Promise((innerResolve, innerReject) => {
                if (weapon.id === "fist") {
                    drawImageWithColorChange(require(`@/assets/img/weapons/fist.webp`), "#d0d0d0")
                        .then((e) => {
                            images.push({
                                id: "fist",
                                wType: "fist",
                                image: e,
                            });
                            innerResolve();
                        })
                        .catch((error) => {
                            innerReject(error);
                        });
                }
                else {
                    drawImageWithColorChange(require(`@/assets/img/weapons/${weapon.wType.id}.webp`), material.color)
                        .then((e) => {
                            images.push({
                                id: weapon.id,
                                wType: weapon.wType,
                                image: e,
                            });
                            innerResolve();
                        })
                        .catch((error) => {
                            innerReject(error);
                        });
                }
            });
            promises.push(promise);
        });



        Promise.all(promises)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
    });
}

const categories = ["backpack", "smelter", "research", "recipes", "packs", "fight"]

export function savePlayer() {
    let save = {};
    categories.forEach(category => {
        let items;
        if (category == "fight") {
            items = store.getters[category + '/getPlayer'];
        } else {
            items = store.getters[category + '/getItems'];
        }

        save[category] = items;
    });
    localStorage.setItem("KnightSteinSaveFile", JSON.stringify(save));
    console.log("Game Saved");
}

export function loadPlayer() {
    let savedData = localStorage.getItem("KnightSteinSaveFile");
    if (savedData) {
        let save = JSON.parse(savedData);
        categories.forEach(category => {
            let items = save[category];
            if (items) {
                if (category == "fight") {
                    store.commit(category + '/setPlayer', items);
                } else {
                    store.commit(category + '/setItems', items);
                }
            }
        });
        console.log("Game Loaded");
    } else {
        console.log("No save file found");
    }
}

export function resetPlayer() {
    categories.forEach(category => {
        if (category == "packs") {
            store.commit('packs/setItems', ["starter"]);
        }

        else if (category == "fight") {
            store.commit('fight/setPlayer', createPlayer())
        }

        else {
            store.commit(category + '/setItems', []);
        }
    });
    store.commit('resetUnlock');
    store.dispatch("packs/unlockFeatures")
    console.log("Game Reset");
}