import { capitalize } from "@/assets/scripts/utils.js";

export function generateWeaponId(material, wType) {
    return `${material.id}${capitalize(wType.id)}`;
}

export function generateWeaponName(material, wType) {
    return `${material.name} ${wType.name}`;
}

//PERK
export function generatePerkWeaponId(item, perk) {
    return `${perk.id}${capitalize(item.material.id)}${capitalize(item.wType.id)}`;
}

export function generatePerkWeaponName(item, perk) {
    return `${perk.name} ${item.material.name} ${item.wType.name}`;
}
