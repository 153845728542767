import { preloadJson, createAllWeapons, createAllOreImages, createAllWeaponImages, loadPlayer, savePlayer, resetPlayer } from "@/assets/scripts/load"

export default {
    preload({ commit, dispatch }) {
        commit("setLoadText", "Loading Game Data")
        loadPlayer();
        dispatch("packs/unlockFeatures")
        return preloadJson().then((e) => {
            commit('setData', e);
            commit("setLoadText", "Loading Weapon Data")
            return createAllWeapons().then((e) => {
                commit('forge/setItems', e, { root: true });
                dispatch("initMainTimer")
                commit("setLoadText", "Loading Material Images");
                return createAllOreImages().then(() => {
                    commit("setLoadText", "Loading Weapon Images")
                    return createAllWeaponImages(e)
                });

            });
        });
    },
    reset({ commit }) {
        resetPlayer();
        commit('showSnackbar', {
            color: 'warning',
            text: 'Your Game has been resetet.',
        });
    },
    initMainTimer({ state, dispatch }) {
        state.timer.main && clearInterval(state.timer.main);
        state.timer.main = setInterval(() => {
            dispatch("smelter/update")
            dispatch("research/update")
            dispatch("forge/update")
            dispatch("fight/update")
        }, state.gameSpeed);
    },
    savePlayer({ state, commit }) {
        savePlayer(state.player);
        commit('showSnackbar', {
            color: 'success',
            text: 'Your changes have been saved.',
        });
    },

}