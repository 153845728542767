import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import "@mdi/font/css/materialdesignicons.css";

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { mdi, aliases } from 'vuetify/iconsets/mdi-svg'

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'dark'
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdiSvg: mdi,
        },
    }
})

createApp(App)
    .use(vuetify)
    .use(router)
    .use(store)
    .mount('#app')
