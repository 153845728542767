//smelter actions
export default {
    update({ state, commit, dispatch }) {
        if (state.items.length == 0 || state.items[0] == undefined) return;

        let item = state.items[0];

        if (item.time == undefined) {
            item.time = 0;
            item.maxTime = 10;
        } else {
            item.time += 1;
        }

        if (item.time > item.maxTime) {
            let material = item.material;
            let amount = item.wType.size;
            dispatch("backpack/addItems", { item: material, amount: amount }, { root: true })

            commit("log/add", { text: 'You have successfully smelted {}!', item: item }, { root: true })

            commit("removeItem", item)
        }
    },
    addFromBackpack({ commit }, item) {
        let clone = JSON.parse(JSON.stringify(item));
        clone.time = null;
        clone.amount = null;
        clone.uid = clone.id + Date.now();
        commit('addItem', clone);
        commit('backpack/removeAmount', { item: item, amount: 1 }, { root: true });
    },
    removeToBackpack({ commit, dispatch }, item) {
        item.time = null;
        commit('removeItem', item);
        dispatch('backpack/addItems', { item: item }, { root: true });
    }
}