
export default {
    setData(state, data) {
        state.data = data;
    },
    setLoadText(state, text) {
        state.loadText = text;
    },
    setGameSpeed(state, speed) {
        state.gameSpeed = speed;
    },
    togglehideImages(state) {
        state.hideImages = !state.hideImages;
    },
    showSnackbar(state, payload) {
        state.snackbar.visible = true;
        state.snackbar.color = payload.color;
        state.snackbar.text = payload.text;
    },
    hideSnackbar(state) {
        state.snackbar.visible = false;
    },
    unlock(state, item) {
        state.unlocked.push(item)
    },
    resetUnlock(state) {
        state.unlocked = ["status", "map", "packs", "backpack", "fight", "settings"]
    }
}