export default {
    setItems(state, items) {
        state.items = items
    },
    setUpgradedItem(state, item) {
        state.upgradedItem = item
    },
    setDismantleItem(state, item) {
        state.dismantleItem = item
    },
    clearUpgradedItem(state) {
        state.upgradedItem = null
    },
    clearDismantleItem(state) {
        state.dismantleItem = null
    }
}