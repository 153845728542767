import store from "@/store";
import { createEnemy } from "@/assets/scripts/enemy";
import { roundToTwo } from "@/assets/scripts/utils";

export function respawn(target) {
    target.health = target.maxHealth;
    target.weapon.cthreshold = 0;
    if (target.weapon.wType.type == "gun") {
        target.weapon.cAmmo = target.weapon.wType.stats.maxAmmo;
        target.weapon.reloading = false;
    }
}

export function dropItems(enemy) {
    const item = enemy.weapon;
    delete item.cthreshold
    store.dispatch("backpack/addItems", { item: item, amount: 1 });
    store.commit("log/add", { text: `${enemy.name} died and dropped {}`, item: item }, { root: true })
}

export function attack(source, target) {
    target.health -= roundToTwo(source.weapon.damage);


    if (target.health <= 0) {
        target.health = 0;
        if (target.id === "player") {
            store.dispatch("fight/died")
        } else {
            dropItems(target);
            target = createEnemy();
            store.commit('fight/setEnemy', target);
            respawn(target);
        }
    }
}

export function updateFight(source, target) {
    if (source.health <= 0) return
    handleReload(source)
    if (source.weapon.reloading) return
    handleAttack(source, target);
}

function handleReload(source) {
    if (source.weapon.reloading) {
        source.weapon.cthreshold += source.weapon.speed;
        if (source.weapon.cthreshold >= source.weapon.wType.stats.reload * source.weapon.threshold) {
            source.weapon.reloading = false;
            source.weapon.cthreshold = 0;
            source.weapon.cAmmo = source.weapon.wType.stats.maxAmmo;
        }
    }
}

function handleAttack(source, target) {
    source.weapon.cthreshold += source.weapon.speed;

    if (source.weapon.cthreshold >= source.weapon.threshold) {
        if (source.weapon.wType.type == "gun") {
            source.weapon.cAmmo--;
            if (source.weapon.cAmmo <= 0) {
                source.weapon.reloading = true;
            }
        }

        source.weapon.cthreshold = 0;
        attack(source, target);
    }
}